import React from 'react';
import {
  Button,
  Text,
  TextTypography as TYPOGRAPHY,
  Dialog as DialogComponent,
} from 'wix-ui-tpa';
import { useFormActions } from '../../Hooks/useFormActions';
import { DataHooks } from './dataHooks';
import { classes, st } from './Dialog.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export interface DialogProps {
  isOpen: boolean;
  titleText?: string;
  contentText: string;
  confirmButtonText: string;
}

const Dialog: React.FC<DialogProps> = ({
  isOpen,
  titleText,
  contentText,
  confirmButtonText,
}) => {
  const { onDialogClose } = useFormActions();
  const { isMobile } = useEnvironment();

  return (
    <DialogComponent
      data-hook={DataHooks.Root}
      isOpen={isOpen}
      onClose={() => onDialogClose()}
      className={st(classes.root, { isMobile })}
      notFullscreenOnMobile
    >
      {titleText && (
        <Text
          className={classes.title}
          data-hook={DataHooks.Title}
          typography={TYPOGRAPHY.largeTitle}
        >
          {titleText}
        </Text>
      )}
      <Text
        className={classes.content}
        data-hook={DataHooks.Content}
        typography={TYPOGRAPHY.listText}
      >
        {contentText}
      </Text>
      <ConfirmButton confirmButtonText={confirmButtonText} />
    </DialogComponent>
  );
};

const ConfirmButton: React.FC<Partial<DialogProps>> = ({
  confirmButtonText,
}) => {
  const { onDialogClose } = useFormActions();

  return (
    <Button
      data-hook={DataHooks.Button}
      className={classes.button}
      onClick={() => onDialogClose()}
    >
      {confirmButtonText}
    </Button>
  );
};

export default Dialog;
