import Text, { TextType } from '../../Text/Text';
import { classes, st } from './SectionTitle.st.css';
import settingsParams from '../../settingsParams';
import React from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { SectionTitleDataHooks } from './dataHooks';
import { Divider } from 'wix-ui-tpa';
import { TagName } from '../../../../utils/tagName.const';

export type SectionTitleProps = {
  label: string;
  tagName: TagName;
};

export function SectionTitle({ label, tagName }: SectionTitleProps) {
  const settings = useSettings();

  return (
    <>
      <Text
        type={TextType.Title}
        data-hook={SectionTitleDataHooks.LABEL}
        className={st(classes.title, {
          textAlignment: settings.get(settingsParams.titlesAlignment),
        })}
        tagName={tagName}
      >
        {label}
      </Text>
      <Divider
        className={classes.divider}
        data-hook={SectionTitleDataHooks.DIVIDER}
      />
    </>
  );
}
