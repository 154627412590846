import React from 'react';
import { RadioButton, RadioButtonTheme } from 'wix-ui-tpa';
import { classes, st } from './PaymentOption.st.css';
import Text from '../../../Text/Text';
import { PaymentOptionDataHooks } from './dataHooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import settingsParams from '../../../settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

export type PaymentOptionProps = {
  id: string;
  selected: boolean;
  disabled: boolean;
  label: string;
  validUntil?: string;
  suffix?: string;
  onChange: Function;
};

export const PaymentOption: React.FC<PaymentOptionProps> = ({
  id,
  label,
  disabled,
  selected,
  suffix,
  validUntil,
  onChange,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const theme = settings.get(settingsParams.fieldsBorderStyle);

  return (
    <RadioButton
      className={st(classes.root, { isMobile, theme })}
      theme={RadioButtonTheme.Box}
      data-hook={PaymentOptionDataHooks.PaymentOption}
      checked={selected}
      key={id}
      value={id}
      disabled={disabled}
      onChange={() => onChange(id)}
      aria-labelledby={`${id}-label`}
      aria-describedby={`${id}-validUntil ${id}-suffix`}
    >
      <div className={classes.content}>
        <div className={classes.labelWrapper}>
          <Text
            id={`${id}-label`}
            data-hook={PaymentOptionDataHooks.Label}
            className={classes.primary}
            aria-hidden
          >
            {label}
          </Text>
          {validUntil ? (
            <div>
              <Text
                id={`${id}-validUntil`}
                data-hook={PaymentOptionDataHooks.ValidUntil}
                className={classes.secondary}
                aria-hidden
              >
                {validUntil}
              </Text>
            </div>
          ) : null}
        </div>
        {suffix ? (
          <div className={classes.suffixWrapper}>
            <Text
              id={`${id}-suffix`}
              data-hook={PaymentOptionDataHooks.Suffix}
              className={classes.primary}
              aria-hidden
            >
              {suffix}
            </Text>
          </div>
        ) : null}
      </div>
    </RadioButton>
  );
};
